<template>
  <div>
    <el-table :data="tableData" stripe header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>

      <el-table-column prop="deviceTypeName" label="设备类型"> </el-table-column>
      <el-table-column prop="deviceCode" label="设备编码"> </el-table-column>
      <el-table-column prop="totle" label="累计水量(m³)"> </el-table-column>
      <el-table-column prop="" label="状态">
        <template slot-scope="scope">
          <div> {{ scope.row.optStatus == 1 ? '开启' : '关闭' }} </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="开关">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.optStatusSwitch" @change="deviceSwitch($event, scope.row)"> </el-switch>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';
export default {
  components: {},
  data() {
    return {
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    deviceSwitch(e, item) {
      console.log(e, item);
      postRequest('/api/iot/sdsj/optSdsj', { deviceCode: item.deviceCode, optType: e ? '1' : '0' })
        .then((res) => {
          this.getList();
        })
        .catch((error) => {});
    },
    getList() {
      postRequest('/api/iot/device/deviceList', {
        deviceType: 11
      })
        .then((res) => {
          res.forEach((e) => {
            e.detail = JSON.parse(e.detailInfo);
            e.totle = e.detail.water;
            e.optStatusSwitch = e.optStatus == 1;
          });
          this.tableData = res;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
