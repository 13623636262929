<template>
  <div>
    <el-table :data="tableData" stripe header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="deviceName" label="名称"> </el-table-column>
      <el-table-column prop="value" label="图标">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.iconUrl"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="deviceTypeName" label="设备类型"> </el-table-column>
      <el-table-column prop="value" label="状态">
        <template slot-scope="scope">
          {{ scope.row.onlineStatus == 0 ? '在线' : '离线' }}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="最近更新时间"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';
export default {
  components: {},
  data() {
    return {
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      postRequest('/api/iot/szsw/sw/devicestatus', {})
        .then((res) => {
          this.tableData = res;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
