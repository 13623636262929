<template>
  <div class="">
    <div class="device">
      <div v-for="item in tianqiList" :key="item.id" class="item">
        <img class="qx-img" :src="item.img" alt="" />
        <div class="content">
          <div>{{ item.name }}</div>
          <div class="value" :style="{ color: item.color }">{{ item.value }}{{ item.unit }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div id="szsw1" class="chart-item"></div>
      <div id="szsw2" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="szsw3" class="chart-item"></div>
      <div id="szsw4" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="szsw5" class="chart-item"></div>
      <div id="szsw6" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="szsw7" class="chart-item"></div>
      <div id="szsw8" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="szsw9" class="chart-item"></div>
      <div id="szsw10" class="chart-item"></div>
    </div>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';

import dayjs from 'dayjs';
import * as echarts from 'echarts';
export default {
  components: {},
  data() {
    return {
      tianqiList: [],
      startTime: '',
      endTime: ''
    };
  },
  created() {},
  mounted() {
    this.startTime = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
    this.endTime = dayjs().format('YYYY-MM-DD');
    this.getQixiang();
    this.getChartData();
  },
  methods: {
    getQixiang() {
      postRequest('/api/iot/szsw/qxDetail', { deviceId: 17 })
        .then((res) => {
          res.forEach((e) => {
            if (e.sign == 'AA1') {
              e.img = require('../../../images/qx/kqwd.png');
              e.color = '#02B98F';
              e.sort = 1;
            }
            if (e.sign == 'AF1') {
              e.img = require('../../../images/qx/jyl.png');
              e.color = '#A28DFF';
              e.sort = 5;
            }
            if (e.sign == 'e2') {
              e.img = require('../../../images/qx/trwd.png');
              e.color = '#1890FF';
              e.sort = 3;
            }
            if (e.sign == 'e3') {
              e.img = require('../../../images/qx/trsd.png');
              e.color = '#FA8F00';
              e.sort = 4;
            }
            if (e.sign == 'AB1') {
              e.img = require('../../../images/qx/kqsd.png');
              e.color = '#FF7474';
              e.sort = 2;
            }
            if (e.sign == 'AL1') {
              e.img = require('../../../images/qx/gzqd.png');
              e.color = '#FFBA00';
              e.sort = 7;
            }
            if (e.sign == 'AD1') {
              e.img = require('../../../images/qx/fs.png');
              e.color = '#34CBF8';
              e.sort = 6;
            }
            if (e.sign == 'AE1') {
              e.img = require('../../../images/qx/fx.png');
              e.color = '#02B98F';
              e.sort = 11;
            }
          });
          this.tianqiList = res;
          this.getSzDetail();
        })
        .catch((error) => {});
    },
    getSzDetail() {
      postRequest('/api/iot/szsw/qxDetail', { deviceId: 19 })
        .then((res) => {
          res.forEach((e) => {
            if (e.sign == 'BR1') {
              e.img = require('../../../images/qx/sw.png');
              e.color = '#7BCB3F';
              e.sort = 8;
            }
            if (e.sign == 'BU1') {
              e.img = require('../../../images/qx/ry.png');
              e.color = '#65789B';
              e.sort = 9;
            }
            if (e.sign == 'CG1') {
              e.img = require('../../../images/qx/yy.png');
              e.color = '#FF7474';
              e.sort = 12;
            }
            if (e.sign == 'BS1') {
              e.img = require('../../../images/qx/ph.png');
              e.color = '#FA8EFF';
              e.sort = 10;
            }
          });
          this.tianqiList = _.sortBy([...this.tianqiList, ...res], function (item) {
            return item.sort;
          });
        })
        .catch((error) => {});
    },
    getChartData() {
      postRequest('/api/iot/szsw/chart', { deviceId: 17, startTime: this.startTime, endTime: this.endTime })
        .then((res) => {
          this.chart1(res.airtemperature);
          this.chart2(res.airhumidity);
          this.chart3(res.soiltemperature);
          this.chart4(res.soilhumidity);
          this.chart5(res.rainfall);
          this.chart6(res.windpower);
          this.chart7(res.lightintensity);
        })
        .catch((error) => {});
      postRequest('/api/iot/szsw/chart', { deviceId: 19, startTime: this.startTime, endTime: this.endTime })
        .then((res) => {
          this.chart8(res.watertemperature);
          this.chart9(res.wateroxygen);
          this.chart10(res.waterph);
        })
        .catch((error) => {});
    },
    chart1(list) {
      let option = {
        color: ['#02B98F'],
        title: [
          {
            text: '空气温度近七日变化'
          }
        ],
        legend: {
          data: ['空气温度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '空气温度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line'
          }
        ]
      };
      this.setChart('szsw1', option);
    },
    chart2(list) {
      let option = {
        color: ['#02B98F'],
        title: [
          {
            text: '空气湿度近七日变化'
          }
        ],
        legend: {
          data: ['空气湿度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '空气湿度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255, 116, 116, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(255, 116, 116, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('szsw2', option);
    },
    chart3(list) {
      let option = {
        color: ['#5B8FF9'],
        title: [
          {
            text: '土壤温度近七日变化'
          }
        ],
        legend: {
          data: ['土壤温度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '土壤温度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line'
          }
        ]
      };
      this.setChart('szsw3', option);
    },
    chart4(list) {
      let option = {
        color: ['#02B98F'],
        title: [
          {
            text: '土壤湿度近七日变化'
          }
        ],
        legend: {
          data: ['土壤湿度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '土壤湿度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(250, 143, 0, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(250, 143, 0, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('szsw4', option);
    },
    chart5(list) {
      let option = {
        title: [
          {
            text: '降水量近七日变化'
          }
        ],
        legend: {
          data: ['降水量']
        },
        color: ['#A28DFF'],
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '降水量',
            type: 'bar',
            barWidth: 20,
            data: list.map((e) => {
              return e.value;
            }),
            z: 10,
            zlevel: 0
          },
          {
            // 分隔
            type: 'pictorialBar',
            itemStyle: {
              normal: {
                color: '#FFFFFF'
              }
            },
            symbolRepeat: 'fixed',
            symbolMargin: 6,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [20, 2],
            symbolPosition: 'start',
            symbolOffset: [0, -1],
            // symbolBoundingData: this.total,
            data: list.map((e) => {
              return e.value;
            }),
            width: 25,
            z: 0,
            zlevel: 1
          }
        ]
      };
      this.setChart('szsw5', option);
    },
    chart6(list) {
      let option = {
        color: ['#02B98F'],
        title: [
          {
            text: '风速近七日变化'
          }
        ],
        legend: {
          data: ['风速']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '风速',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(52, 203, 248, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(52, 203, 248, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('szsw6', option);
    },
    chart7(list) {
      let option = {
        color: ['#F6BD16'],
        title: [
          {
            text: '光照强度近七日变化'
          }
        ],
        legend: {
          data: ['光照强度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '光照强度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'bar'
          }
        ]
      };
      this.setChart('szsw7', option);
    },
    chart8(list) {
      let option = {
        color: ['#7BCB3F'],
        title: [
          {
            text: '水温近七日变化'
          }
        ],
        legend: {
          data: ['水温']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '水温',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line'
          }
        ]
      };
      this.setChart('szsw8', option);
    },
    chart9(list) {
      let option = {
        color: ['#65789B'],
        title: [
          {
            text: '溶氧量近七日变化'
          }
        ],
        legend: {
          data: ['溶氧']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '溶氧',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'bar'
          }
        ]
      };
      this.setChart('szsw9', option);
    },
    chart10(list) {
      let option = {
        title: [
          {
            text: 'PH值近七日变化'
          }
        ],
        legend: {
          data: ['PH值']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'PH值',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(250, 142, 255, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(250, 142, 255, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('szsw10', option);
    },

    setChart(id, option) {
      var myChart = echarts.init(document.getElementById(id));

      myChart.setOption(option);
    }
  }
};
</script>
<style scoped lang="scss">
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}
.chart-item {
  width: 49%;
  height: 260px;
  background-color: rgba(248, 248, 249, 1);
  padding: 12px;
  box-sizing: border-box !important;
}

.qx-img {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.device {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
}
.item {
  background-color: #f8f8f9;
  flex: 0 0 13%;
  flex-shrink: 0;
  margin: 20px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    color: #2c3542;
    .value {
      color: #02b98f;
      margin-top: 6px;
    }
  }
}
</style>
