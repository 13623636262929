<template>
  <div class="fill">
    <div class="title">
      <div> 水位变化曲线 </div>
      <div class="legend">
        <div class="item"><div class="area" style="background-color: #02b98f"></div> 水渠</div>
        <div class="item"><div class="area" style="background-color: #1890ff"></div> 淤泥河</div>
        <div class="item"><div style="color: #1890ff; margin-right: 5px">--</div> 淤泥河均值</div>
        <div class="item"><div style="color: #ff7474; margin-right: 5px">--</div> 淤泥河临界值</div>
      </div>
    </div>
    <div id="chat1" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { getRequest } from '@/api/api.js';

import dayjs from 'dayjs';
export default {
  components: {},
  data() {
    return {
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    setChart(id, option) {
      console.log(option, 'optionoption');
      var myChart = echarts.init(document.getElementById(id));

      myChart.setOption(option);
    },
    formateRes(resItem) {
      console.log(resItem, 'resItemresItem');
      let val = [
        {
          name: '淤泥河',
          type: 'bar',
          barWidth: 10,
          backgroundStyle: {
            color: '#1890FF'
          },

          data: resItem.szswDailyData.map((sub) => {
            return Number(sub.swList[0].value).toFixed(2);
          }),
          markLine: {
            data: [
              {
                yAxis: resItem.ynhAvg, // 在 y 轴的位置为 50 的位置画标注线
                lineStyle: {
                  type: 'dashed',
                  color: '#1890FF',
                  symbol: 'none'
                },
                label: {
                  formatter: `${resItem.ynhAvg} `,
                  color: '#1890FF'
                }
              },
              {
                yAxis: resItem.ynhMax, // 在 y 轴的位置为 50 的位置画标注线
                lineStyle: {
                  type: 'dashed',
                  color: '#FF7474',
                  symbol: 'none'
                },
                label: {
                  formatter: `${resItem.ynhMax} `,
                  color: '#FF7474'
                }
              }
            ]
          }
        },
        {
          name: '水渠',
          type: 'bar',
          barWidth: 10,
          backgroundStyle: {
            color: '#02B98F'
          },

          data: resItem.szswDailyData.map((sub) => {
            return Number(sub.swList[1] ? sub.swList[1].value : 0).toFixed(2);
          })
        }
      ];
      return val;
    },
    getList() {
      getRequest('/api/iot/szsw/month/sw/daily', {})
        .then((res) => {
          let option = {
            baseOption: {
              timeline: {
                width: '100%',
                axisType: 'category',
                // realtime: false,
                // loop: false,
                autoPlay: true,
                // currentIndex: 2,
                playInterval: 1000,
                // controlStyle: {
                //     position: 'left'
                // },
                data: res.map((e) => {
                  return e.month;
                }),
                // label: {
                //   formatter: function (s) {
                //     return new Date(s).getFullYear();
                //   }
                // },
                bottom: 0,
                left: 0
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'none'
                }
              },
              legend: {
                show: false
              },
              grid: {
                left: '10',
                top: '30',
                right: '40',
                bottom: '100',
                containLabel: true
              },
              title: [
                {
                  text: '',
                  subtext: '单位：m',
                  subtextStyle: {
                    fontSize: 12
                  },
                  left: 0,
                  top: -10
                }
              ],
              color: ['#1890ff', '#02b98f'],
              xAxis: [
                {
                  type: 'category',
                  axisLabel: {
                    textStyle: {
                      // color: 'rgba(255,255,255,.6)',
                      fontSize: 12
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      // color: 'rgba(255,255,255,.2)'
                    }
                  }
                },
                {
                  axisPointer: { show: false },
                  axisLine: { show: false },
                  position: 'bottom',
                  offset: 20
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  axisLine: {
                    show: false
                  },
                  axisPointer: {
                    show: false // 设置为 false 去掉 y 轴的 hover 线
                  }
                }
              ]
            },
            options: res.map((e, index) => {
              return {
                series: this.formateRes(e),
                xAxis: [
                  {
                    data: e.szswDailyData.map((sub) => {
                      return dayjs(sub.today).format('MM.DD');
                    })
                  }
                ],
                yAxis: [{ max: e.ynhMax + 1 }],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    lineStyle: {
                      color: '#dddc6b'
                    }
                  },
                  formatter: function (params, ticket, callback) {
                    var htmlStr = `水位` + '<br/>';

                    for (var i = 0; i < params.length; i++) {
                      var param = params[i];
                      var xName = param.name; //x轴的名称
                      var seriesName = param.seriesName; //图例名称
                      var value = param.value; //y轴值
                      var color = param.color; //图例颜色

                      if (i === 0) {
                        htmlStr += res[index].month.substr(0, 4) + '.' + xName + '' + '<br/>'; //x轴的名称
                      }
                      htmlStr += `<div>`;

                      htmlStr +=
                        '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' +
                        color +
                        ';"></span>';
                      let unit = 'm';

                      htmlStr += seriesName + ':' + value + unit;

                      htmlStr += '</div>';
                    }
                    htmlStr += `<div><span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:#1890FF;"></span>淤泥河均值:${e.ynhAvg}m</div>`;
                    htmlStr += `<div><span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:#FF7474;"></span>淤泥河临界值:${e.ynhMax}m</div>`;
                    htmlStr += '</div>';
                    return htmlStr;
                  }
                }
              };
            })
          };

          this.setChart('chat1', option);
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.fill {
  padding: 0 20px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #2c3542;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
}
.chart {
  // margin: 0 auto;
  // width: 80%;
  height: 60vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
.legend {
  display: flex;
  align-items: center;
  .item {
    display: flex;
    font-size: 12px;
    color: #2c3542;
    margin-right: 20px;
    align-items: center;
    .area {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
}
</style>
