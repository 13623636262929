<template>
  <div class="fill">
    <div class="title">
      <div> 水质变化曲线 </div>
      <div class="legend">
        <div class="item"><div class="area" style="background-color: #ff7474"></div> 温度</div>
        <div class="item"><div class="area" style="background-color: #65789b"></div> 溶氧量</div>
        <div class="item"><div style="color: #fa8f00; margin-right: 5px">--</div> 溶氧量临界值</div>
        <div class="item"><div style="color: #ff7474; margin-right: 5px">--</div> 温度临界值</div>
      </div>
    </div>
    <div id="chat1" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { getRequest } from '@/api/api.js';

import dayjs from 'dayjs';
export default {
  components: {},
  data() {
    return {
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    setChart(id, option) {
      var myChart = echarts.init(document.getElementById(id));
      myChart.setOption(option);
    },
    formateRes(resItem) {
      return [
        {
          name: '温度',
          type: 'bar',
          barWidth: 10,
          backgroundStyle: {
            color: '#FF7474'
          },

          data: resItem.szswDailyData.map((e) => {
            return Number(e.swList[0].value);
          }),
          markLine: {
            data: [
              {
                yAxis: 20, // 在 y 轴的位置为 50 的位置画标注线
                lineStyle: {
                  type: 'dashed',
                  color: '#FF7474',
                  symbol: 'none'
                },
                label: {
                  formatter: `${resItem.br1Max} ℃(max)`,
                  color: '#FF7474'
                }
              },
              {
                yAxis: 7, // 在 y 轴的位置为 50 的位置画标注线
                lineStyle: {
                  type: 'dashed',
                  color: '#FF7474',
                  symbol: 'none'
                },
                label: {
                  formatter: `${resItem.br1Min} ℃(min)`,
                  color: '#FF7474'
                }
              }
            ]
          }
        },
        {
          name: '溶氧量',
          type: 'line',
          yAxisIndex: 1,
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          showSymbol: false,
          lineStyle: {
            normal: {
              color: '#65789b',
              width: 2
            }
          },

          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(255, 116, 116, 0.4)'
                  },
                  {
                    offset: 0.8,
                    color: 'rgba(255, 116, 116, 0.1)'
                  }
                ],
                false
              ),
              shadowColor: 'rgba(0, 0, 0, 0.1)'
            }
          },
          itemStyle: {
            normal: {
              color: '#00d887',
              borderColor: 'rgba(255, 116, 116, .1)',
              borderWidth: 12
            }
          },
          data: resItem.szswDailyData.map((e) => {
            return Number(e.swList[1].value);
          }),
          markLine: {
            data: [
              {
                yAxis: 1.2, // 在 y 轴的位置为 50 的位置画标注线
                lineStyle: {
                  type: 'dashed',
                  color: '#FA8F00',
                  symbol: 'none'
                },
                label: {
                  formatter: `${resItem.bu1Max} mg/L(max)`,
                  color: '#FA8F00'
                }
              },
              {
                yAxis: 0.2, // 在 y 轴的位置为 50 的位置画标注线
                lineStyle: {
                  type: 'dashed',
                  color: '#FA8F00',
                  symbol: 'none'
                },
                label: {
                  formatter: `${resItem.bu1Min} mg/L(min)`,
                  color: '#FA8F00'
                }
              }
            ]
          }
        }
      ];
    },
    getList() {
      getRequest('/api/iot/szsw/month/sz/daily', {})
        .then((res) => {
          let option = {
            baseOption: {
              timeline: {
                width: '100%',
                axisType: 'category',
                // realtime: false,
                // loop: false,
                autoPlay: true,
                // currentIndex: 2,
                playInterval: 1000,
                // controlStyle: {
                //     position: 'left'
                // },
                data: res.map((e) => {
                  return e.month;
                }),
                // label: {
                //   formatter: function (s) {
                //     return new Date(s).getFullYear();
                //   }
                // },
                bottom: 0,
                left: 0
              },

              legend: {
                show: false
              },
              title: [
                {
                  text: '',
                  subtext: '温度：℃',
                  subtextStyle: {
                    fontSize: 12
                  },
                  right: 0,
                  top: -10
                },
                {
                  text: '',
                  subtext: '溶氧量：mg/L',
                  subtextStyle: {
                    fontSize: 12
                  },
                  left: 0,
                  top: -10
                }
              ],
              grid: {
                left: '10',
                top: '30',
                right: '70',
                bottom: '100',
                containLabel: true
              },

              xAxis: [
                {
                  type: 'category',
                  axisLabel: {
                    textStyle: {
                      // color: 'rgba(255,255,255,.6)',
                      fontSize: 12
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      // color: 'rgba(255,255,255,.2)'
                    }
                  }
                },
                {
                  axisPointer: { show: false },
                  axisLine: { show: false },
                  position: 'bottom',
                  offset: 20
                }
              ],

              yAxis: [
                {
                  type: 'value',
                  name: '',
                  axisTick: { show: false },
                  axisLine: {
                    show: false
                  }
                },
                {
                  type: 'value',
                  name: '',
                  name: '',
                  axisTick: { show: false },
                  axisLine: {
                    show: false
                  }
                }
              ],
              series: []
            },
            options: res.map((e, index) => {
              return {
                series: this.formateRes(e),
                xAxis: [
                  {
                    data: e.szswDailyData.map((sub) => {
                      return dayjs(sub.today).format('MM.DD');
                    })
                  }
                ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    lineStyle: {
                      color: '#dddc6b'
                    }
                  },
                  formatter: function (params, ticket, callback) {
                    var htmlStr = `水质` + '<br/>';
                    for (var i = 0; i < params.length; i++) {
                      var param = params[i];
                      var xName = param.name; //x轴的名称
                      var seriesName = param.seriesName; //图例名称
                      var value = param.value; //y轴值
                      var color = param.color; //图例颜色
                      console.log(seriesName, index, '======???');
                      if (i === 0) {
                        htmlStr += res[index].month.substr(0, 4) + '.' + xName + '' + '<br/>'; //x轴的名称
                      }
                      htmlStr += `<div>`;
                      let unit = '';
                      if (seriesName == '温度') {
                        unit = '℃';
                        color = '#ff7474';
                      }
                      if (seriesName == '溶氧量') {
                        unit = 'mg/L';
                        color = '#65789b';
                      }
                      htmlStr +=
                        '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' +
                        color +
                        ';"></span>';

                      htmlStr += seriesName + ':' + value + unit;

                      htmlStr += '</div>';
                    }
                    htmlStr += `<div><span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:#fa8f00;"></span>溶氧量临界值:${e.bu1Min}mg/L-${e.bu1Max}mg/L</div>`;
                    htmlStr += `<div><span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:#FF7474;"></span>温度临界值:${e.br1Min}℃-${e.br1Max}℃</div>`;
                    htmlStr += '</div>';
                    return htmlStr;
                  }
                }
              };
            })
          };

          this.setChart('chat1', option);
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.fill {
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // top: 0;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #2c3542;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
}
.chart {
  margin: 0 auto;
  width: 80%;
  height: 60vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
.legend {
  display: flex;
  align-items: center;
  .item {
    display: flex;
    font-size: 12px;
    color: #2c3542;
    margin-right: 20px;
    align-items: center;
    .area {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
}
</style>
