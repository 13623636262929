<template>
  <div>
    <el-table :data="tableData" stripe header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="name" label="名称">
        <template slot-scope="scope">
          <div :style="{ color: scope.row.waring ? 'red' : '' }">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="值">
        <template slot-scope="scope">
          <div :style="{ color: scope.row.waring ? 'red' : '' }">
            {{ scope.row.value }}
            {{ scope.row.unit }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="最近更新时间"> </el-table-column>
      <el-table-column prop="unit" label="临界值">
        <template slot-scope="scope">
          <div :style="{ color: scope.row.waring ? 'red' : '' }"
            >{{ scope.row.minValue }}{{ scope.row.unit }}- {{ scope.row.maxValue }}{{ scope.row.unit }}
          </div></template
        >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';
export default {
  components: {},
  data() {
    return {
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      postRequest('/api/iot/szsw/szDetail', {})
        .then((res) => {
          this.tableData = res.trList;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
